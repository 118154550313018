<template>
    <div>
        <div v-if="!items || !items.length">No Messages Yet.</div>
        <a v-for="(item, index) in items" :key="'task' + index" :class="$style.item" @click="click(item, items)" style="padding-bottom: 10px;">
            <div :class="$style.container">
                <p :class="$style.headerText">{{item.subject}}</p>
                <p style="color: #515a6e; overflow: hidden; text-overflow: ellipsis;">{{item.to_email}}</p>
                <!-- <p><img :src="require(`Assets/images/view.png`)" :class="$style.img"/></p> -->
            </div>
        </a>
        <div v-if="(items.length > 0)" :class="$style.containerCount" @click="messageList">
           See All..
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            items: {
                type: Array,
                required: true,
                default: () => []
            }
        },
        methods: {
            click(item, items) {
                this.$emit('onClick', item, items);
            },
            messageList() {
                 this.$router.push({ path: '/messageList' });
            }
        }
    }
</script>
<style lang="scss" module>
    .item {
        display: flex;
        span {
            width: 100%;
        }

        b {
            white-space: nowrap;
        }
    }
    
    .headerText{
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 15px;
        font-weight: 400;
    }

    .container{
        padding: 5px 0px 5px 10px;
        width: 100%;
        color: black;
        border-bottom: #81c3ec solid 1px;
    }

    .containerCount{
        color: #81c3ec;
        margin-bottom: 20px;
        font-size: 16px;
        cursor: pointer;
        float: right;
        font-weight: bolder;
        &:focus,
        &:hover {
            color: orange
        }
    }

    .img{
        height: 25px;
        margin-left:95%;
        position: relative;
        margin-top: -22px;
    }
</style>
